import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useRef, useState } from "react"
import LazyLoad from "react-lazyload"
import { SlideDown } from "react-slidedown"
import Benefit from "../../components/common/Benefit-box/Benefit.js"
import CTA from "../../components/common/CTA.js"
import Frame from "../../components/common/frame"
import Navigation from "../../components/navigation"
import "../../styles/pages/home-2024.scss"
import "../../styles/pages/monica-ai.scss"

const MonicaAi = () => {
  const data = useStaticQuery(graphql`
    query MonicaAiQuery {
      SuperOps {
        pages(where: { title: "Meet Monica AI" }) {
          ctaBoxes {
            type
            theme
            customBackgroundColor
            heading {
              html
            }
            primaryButtonText
            primaryButtonLink
            card {
              image {
                url
              }
            }
          }
          subtitle
          content {
            html
          }

          seo {
            description
            title
          }

          heroSection {
            images {
              id
              url
            }
            heroImage {
              url
            }
            content {
              html
            }
            heading {
              html
            }
          }

          newField {
            ... on SuperOps_Card {
              id
              text
              subtext
              image {
                id
                url
              }
              content {
                html
              }
            }
          }
        }
      }
    }
  `)

  const {
    ctaBoxes,
    newField,
    content,
    subtitle,
    seo,
    heroSection,
  } = data.SuperOps.pages[0]

  const [selectedCard, setSelectedCard] = useState(0)
  const videoRef = useRef(null)

  useEffect(() => {
    newField[1].image.forEach(video => {
      const videoPreload = new Image()
      videoPreload.src = video.url
    })
  }, [newField])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [selectedCard])

  return (
    <div className="monica-ai-container">
      <Frame
        seo={seo}
        IsFooterVisble="No"
        theme="dark"
        bodyClassName="bg-black"
        page="Monica AI"
        className="outer-container"
      >
        <div className="nav-wrapper">
          <header>
            <Navigation
              page="Home"
              dskScroll={400}
              darkHelloBar
              theme="dark"
              darkTheme
              btn1="secondary white"
              btn2="primary white ml-9"
            />
          </header>
        </div>

        <section className="hero-container">
          <video
            // width={600}
            autoPlay
            loop
            muted
            preload="auto"
            src={heroSection.images[0].url}
            className="ring"
          />
          <div className="title">{parse(heroSection.heading.html)}</div>

          <div className="subtext">{parse(heroSection.content.html)}</div>

          <div className="monica-img">
            {/* <img src={heroSection.heroImage.url} alt="monica-ai-img" /> */}

            <iframe
              src="https://rive.app/s/JeQqpNJeO06yHLwpSqbyqA/embed"
              className="award-coin"
            ></iframe>
          </div>
        </section>

        <section className="monica-description">
          <div className="description-wrapper">
            <div className="title-content">{parse(content.html)}</div>
            <div className="description-subtitle">{parse(subtitle)}</div>
          </div>
        </section>

        <section className="offer-cards">
          <Benefit
            headingHtml={newField[0].content}
            icon={newField[0].image}
            iconClassName="icon-stylings"
            iconBcgColor={newField[0].text}
          />
        </section>

        <section className="carosal">
          <div className="carosal-container">
            <div className="video-wrapper">
              <video
                className="video"
                width={600}
                autoPlay
                loop
                muted
                preload="auto"
                ref={videoRef}
                src={newField[1].image[selectedCard].url}
              >
                Your browser does not support the video tag.
              </video>
            </div>

            <div className="content-wrapper">
              {newField[1].text.map((text, idx) => {
                return (
                  <div
                    className={`contents ${
                      selectedCard === idx ? "active" : ""
                    }`}
                    key={idx}
                    onClick={() => setSelectedCard(idx)}
                  >
                    <p className="title">{text}</p>
                    <p className="subtext">{newField[1].subtext[idx]}</p>
                    {selectedCard === idx ? (
                      <SlideDown>
                        <div className="mb-video-wrapper">
                          <video
                            className="mb-video"
                            autoPlay
                            loop
                            muted
                            preload="auto"
                            ref={videoRef}
                            src={newField[1].image[selectedCard].url}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </SlideDown>
                    ) : (
                      <></>
                    )}
                    <hr
                      className={`line ${selectedCard === idx ? "active" : ""}`}
                    ></hr>
                  </div>
                )
              })}
            </div>
          </div>
        </section>

        <section>
          <p className="coming-soon">Coming soon</p>
        </section>

        <section className="info-cards">
          <Benefit
            headingHtml={newField[2].content}
            icon={newField[2].image}
            iconClassName="icon-stylings"
            iconBcgColor={newField[2].text}
            description={newField[2].subtext}
          />
        </section>

        <section className="cta-container cta normal-cta">
          <LazyLoad>
            <CTA
              data={[ctaBoxes[0]]}
              className="Layout-container"
              lgLeft={6}
              lgRight={3}
              newButton
              isPrimaryPink
              buttonClassName="primary-new"
            />
          </LazyLoad>
        </section>
      </Frame>
    </div>
  )
}

export default MonicaAi
